import React from 'react'
import { Link, graphql } from 'gatsby'
import SideTitle from './sideTitle'
import Img from 'gatsby-image'

const Recommends = ({ recommends }) => {
  const posts = recommends.edges || []

  return (
    <div className="blog-tags mb-12">
      <SideTitle data="Reccomends" />
      <div className="mx-4 md:mx-0 grid grid-cols-2 gap-4 md:gap-6">
        {posts.map(({ node }) => {
          return (
            <div
              key={node.fields.slug}
              className="border bg-white hover:shadow-lg"
            >
              <Link to={node.fields.slug} className="tag">
                <div className="w-full">
                  {node.frontmatter.img &&
                    node.frontmatter.img.childImageSharp &&
                    node.frontmatter.img.childImageSharp.fluid && (
                      <Img
                        className="h-28 md:h-40 object-cover"
                        key={node.frontmatter.img.childImageSharp.fluid.src}
                        fluid={node.frontmatter.img.childImageSharp.fluid}
                      />
                    )}
                </div>
                <div className="px-2 py-3 h-20">
                  <h2 className="text-sm">{node.frontmatter.title}</h2>
                </div>
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Recommends
