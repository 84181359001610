import { Link } from 'gatsby'
import React from 'react'
import Profile from './profile'
import Recommends from './recommend'
import Tags from './tags'

const Sidebar = ({ recommends, tags }) => (
  <>
    <aside className="sidebar w-full">
      <Profile />
      <Recommends recommends={recommends} />
      <Tags tags={tags} />
    </aside>
  </>
)

export default Sidebar
